<template>
  <div id="app">
    <div id="home"></div>
    <div class="header">
      <div class="Hleft">
        <div class="logo"><img src="@/assets/imgs/logo.png" alt="logo" /></div>
        <div class="menu">
          <div :class="{ checked: active === 0 }" @click="active = 0">
            <a href="#home">Home</a>
          </div>
          <div :class="{ checked: active === 1 }" @click="active = 1">
            <a href="#introduce">Introduce</a>
          </div>
          <div :class="{ checked: active === 2 }" @click="active = 2">
            <a href="#follow">Follow&ensp;Us</a>
          </div>
        </div>
      </div>
      <!-- https://twitter.com/OhayoAptos -->
      <div class="Hright">
        <div class="show1">
          <div class="xlogo">
            <a href="https://twitter.com/OhayoAptos" target="_blank">
              <img src="@/assets/imgs/social/x.png" alt="logo" />
            </a>
          </div>
          <div class="btn">Connect Wallet</div>
        </div>
        <div class="show2">
          <div class="xlogo">
            <a href="https://x.com/OhayoAptos" target="_blank">
              <img src="@/assets/imgs/social/x.png" alt="logo" />
            </a>
          </div>
          <div class="circle" @click="menuShow = true">
            <div class="lineBox">
              <hr class="line" />
              <hr class="line" />
              <hr class="line" />
            </div>
          </div>
        </div>
      </div>
      <div
        class="menuList"
        :style="{
          opacity: menuShow ? '1' : '0',
          visibility: menuShow ? 'visible' : 'hidden',
        }"
      >
        <!-- menuShow -->
        <div class="menuListContainer">
          <div class="close" @click="menuShow = false">x</div>
          <div class="menuLogo">
            <img src="@/assets/imgs/logo.png" alt="logo" />
          </div>
          <div class="choiceItem">
            <a href="#home" @click="(active = 0), (menuShow = false)"
              ><div class="item" :class="{ checked1: active === 0 }">
                <div>Home</div>
                <div>
                  <img src="@/assets/imgs/arrow.png" alt="arrow" />
                </div></div
            ></a>
            <a href="#introduce">
              <div
                class="item"
                :class="{ checked1: active === 1 }"
                @click="(active = 1), (menuShow = false)"
              >
                <div>Introduce</div>
                <div>
                  <img src="@/assets/imgs/arrow.png" alt="arrow" />
                </div></div
            ></a>
            <a href="#follow"
              ><div
                class="item"
                :class="{ checked1: active === 2 }"
                @click="(active = 2), (menuShow = false)"
                style="border-bottom: none"
              >
                <div>Follow&ensp;Us</div>
                <div>
                  <img src="@/assets/imgs/arrow.png" alt="arrow" />
                </div></div
            ></a>
          </div>
          <div class="btn btn1">Connect Wallet</div>
          <div class="other">Ohayo Aptos</div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="ptone">
        <div class="topBox">
          <div class="logoBox">
            <img src="@/assets/imgs/logo.png" alt="logo" />
          </div>
          <div class="detail">
            <div>Enter A Blockchain Gaming Revolution</div>
            <div>In Our Anime-Inspired World</div>
          </div>
          <div class="playBtn">
            <img src="@/assets/imgs/playBtn.png" alt="" />
          </div>
        </div>
        <div class="bottomBox">
          <div class="boxItem">
            <div>10K+</div>
            <div>Follow</div>
          </div>
          <div class="boxItem">
            <div>200K+</div>
            <div>NFT Value</div>
          </div>
          <div class="boxItem">
            <div style="margin-left: 5px">100K+</div>
            <div>Airdrop For Season</div>
          </div>
        </div>
        <div id="introduce"></div>
        <div class="logoBox1">
          <a href="https://aptosfoundation.org/" target="_blank"
            ><img src="@/assets/imgs/partners/aptos.png" alt="aptos"
          /></a>
          <a href="https://www.springx.net/" target="_blank"
            ><img src="@/assets/imgs/partners/spbai.png" alt="spbai"
          /></a>
          <a href="" target="_blank">
            <img src="@/assets/imgs/partners/willgame.png" alt="willgame" />
          </a>
        </div>
      </div>
      <div class="pttwo">
        <div class="intro1">
          <img src="@/assets/imgs/introduce.png" alt="introduce" />
        </div>
        <div class="intro2">
          <div>Welcome To Ohayo Aptos,</div>
          <div>The Innovative RWA Game On The Aptos Ecosystem.</div>
          <div>
            Enter A World Where Reality Merges Seamlessly With The Virtual,
          </div>
          <div>
            Reminiscent Of Monopoly But With A Twist Of Japanese Comic
            Characters
          </div>
          <div>Across Multiple Maps.</div>
          <div>
            Engage In Thrilling Battles As You Navigate Through The Game,
          </div>
          <div>Trading Assets, And Conquering Territories,</div>
          <div>All While Earning Real-World Financial Gains.</div>
          <div>Led By Industry Veterans,</div>
          <div>
            Our Team Ensures An Immersive Experience Awaits You In Ohayo Aptos.
          </div>
        </div>
        <div class="intro3">
          <div class="showBox">
            <video
              src="@/assets/imgs/video.mp4"
              v-if="showIndex == 0"
              :class="{ active: showIndex == 0 }"
              controls
            ></video>
            <img
              src="@/assets/imgs/swipper/1.jpg"
              v-else-if="showIndex == 1"
              alt=""
            />
            <img
              src="@/assets/imgs/swipper/2.jpg"
              v-else-if="showIndex == 2"
              alt=""
            />
            <img
              src="@/assets/imgs/swipper/3.jpg"
              v-else-if="showIndex == 3"
              alt=""
            />
            <img
              src="@/assets/imgs/swipper/4.jpg"
              v-else-if="showIndex == 4"
              alt=""
            />
            <img
              src="@/assets/imgs/swipper/5.jpg"
              v-else-if="showIndex == 5"
              alt=""
            />
          </div>
          <div class="listBox" id="follow">
            <div class="imgBoxs">
              <img
                @click="showIndex = 0"
                src="@/assets/imgs/swipper/0.png"
                alt=""
              />
              <img
                @click="showIndex = 1"
                src="@/assets/imgs/swipper/1.jpg"
                alt=""
              />
              <img
                @click="showIndex = 2"
                src="@/assets/imgs/swipper/2.jpg"
                alt=""
              />
              <img
                @click="showIndex = 3"
                src="@/assets/imgs/swipper/3.jpg"
                alt=""
              />
              <img
                @click="showIndex = 4"
                src="@/assets/imgs/swipper/4.jpg"
                alt=""
              />
              <img
                @click="showIndex = 5"
                src="@/assets/imgs/swipper/5.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="ptthree">
        <div class="followTitle">Follow&ensp;Us</div>
        <div class="FollowItem">
          <div class="FollowLeft">
            <div>Social Media:</div>
            <div>You Will Stay Updated With Our Latest News And Events.</div>
          </div>
          <div class="FollowRight">
            <!-- <a href="" target="_blank"> -->
            <img src="@/assets/imgs/social/f.png" alt="logo" />
            <!-- </a> -->
            <a href="https://twitter.com/OhayoAptos" target="_blank">
              <img src="@/assets/imgs/social/x.png" alt="logo" />
            </a>
            <!-- <a href="" target="_blank"> -->
            <img src="@/assets/imgs/social/ig.png" alt="logo" />
            <!-- </a> -->
            <!-- <a href="" target="_blank"> -->
            <img src="@/assets/imgs/social/tik.png" alt="logo" />
            <!-- </a> -->
          </div>
        </div>
        <div class="FollowItem">
          <div class="FollowLeft">
            <div>Messaging Platforms:</div>
            <div>You Will Remain In Constant Contact With Us.</div>
          </div>
          <div class="FollowRight">
            <!-- <a href="" target="_blank"> -->
            <img src="@/assets/imgs/social/dis.png" alt="logo" />
            <!-- </a> -->
            <!-- <a href="https://twitter.com/OhayoAptos" target="_blank"> -->
            <img src="@/assets/imgs/social/tele.png" alt="logo" />
            <!-- </a> -->
            <!-- <a href="" target="_blank"> -->
            <img src="@/assets/imgs/social/what.png" alt="logo" />
            <!-- </a> -->
            <!-- <a href="" target="_blank"> -->
            <img src="@/assets/imgs/social/face.png" alt="logo" />
            <!-- </a> -->
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footerContain">
        <div class="footerTop">
          <div class="footLeft">
            <img src="@/assets/imgs/logo.png" alt="logo" />
          </div>
          <div class="footerRight">
            A Place Leading The Revolution Of Gaming Generations! In This
            Mysterious Two-Dimensional World, You Will Experience Unprecedented
            Fun In Blockchain Games. We Integrate The Latest Web3 Technology To
            Create A Real And Infinitely Possible Virtual Territory.
          </div>
          <div class="footerRight2">
            <div>
              We focus on independent research and development of blockchain
              games and have a strong team and operating partners.
            </div>
            <div>
              The studio's current game product, which has been carefully
              polished for many years: Ohayo Aptos, is a game generation change
              that will bring you unprecedented blockchain gaming fun.
            </div>
            <div>
              We will continue to uphold the concept of quality games and use
              cutting-edge technology and ingenuity to continuously bring new
              experiences to players, so stay tuned and look forward to it!
            </div>
            <div class="footerBottom footerBottom2">
              <a href="https://aptosfoundation.org/" target="_blank">
                <img src="@/assets/imgs/footer/aptos.png" alt="aptos" />
              </a>
              <a href="https://www.springx.net/" target="_blank"
                ><img src="@/assets/imgs/footer/sp.png" alt="sp"
              /></a>
              <a href="" target="_blank"
                ><img src="@/assets/imgs/footer/willgame.png" alt="willgame"
              /></a>
              <a href="https://meson.fi/zh/home" target="_blank"
                ><img src="@/assets/imgs/footer/mes.png" alt="meson"
              /></a>
              <a href="https://www.topaz.so/" target="_blank"
                ><img src="@/assets/imgs/footer/Top.png" alt="topaz"
              /></a>
            </div>
          </div>
        </div>
        <div class="footerBottom">
          <a href="https://aptosfoundation.org/" target="_blank">
            <img src="@/assets/imgs/footer/aptos.png" alt="aptos" />
          </a>
          <a href="https://www.springx.net/" target="_blank"
            ><img src="@/assets/imgs/footer/sp.png" alt="sp"
          /></a>
          <a href="" target="_blank"
            ><img src="@/assets/imgs/footer/willgame.png" alt="willgame"
          /></a>
          <a href="https://meson.fi/zh/home" target="_blank"
            ><img src="@/assets/imgs/footer/mes.png" alt="meson"
          /></a>
          <a href="https://www.topaz.so/" target="_blank"
            ><img src="@/assets/imgs/footer/Top.png" alt="topaz"
          /></a>
        </div>
      </div>
    </div>
    <div class="header2 header">
      <div class="Hleft">
        <div class="logo"><img src="@/assets/imgs/logo.png" alt="logo" /></div>
        <div class="menu">
          <div :class="{ checked: active === 0 }" @click="active = 0">
            <a href="#home">Home</a>
          </div>
          <div :class="{ checked: active === 1 }" @click="active = 1">
            <a href="#introduce">Introduce</a>
          </div>
          <div :class="{ checked: active === 2 }" @click="active = 2">
            <a href="#follow">Follow Us</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "typeface-jockey-one";
export default {
  name: "App",
  components: {},
  data() {
    return {
      active: 0,
      showIndex: 0,
      menuShow: false,
    };
  },
};
</script>


<style src="./assets/index.css" scoped></style>